.pageWrapper {
    position: relative;
    width: 100%;
    height: 100vh;

}

.customizeContainer {
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: auto;
    margin-top: 60px;
    width: 550px;
    height: auto;
    border-radius: 18px;
    background-color: rgba(24, 24, 26, 0.4);
    backdrop-filter: blur(30px);
    border-radius: 12px;
    font-family: 'DM Sans', sans-serif;
    border: 1px solid rgb(78, 78, 82);
    color: white;
}
.link{
    display: flex;
    position: absolute;
    margin-right:auto;
    margin-left: 40px;
    background-color: rgba(24, 24, 26, 0.4);
    backdrop-filter: blur(30px);
    border-radius: 12px;
    padding: 8px;
    gap: 10px;
    align-items: center;
    color:var(--brand-color);
    margin-bottom: 16px;
    font-weight: 700;
    border: 1px solid rgb(78, 78, 82);
    cursor: pointer;
}

.linkBL{
    display: flex;
    position: absolute;
    margin-right:auto;
    right: 40px;
    background-color: rgba(24, 24, 26, 0.4);
    backdrop-filter: blur(30px);
    border-radius: 12px;
    padding: 8px;
    gap: 10px;
    align-items: center;
    color:var(--brand-color);
    margin-bottom: 16px;
    font-weight: 700;
    border: 1px solid rgb(78, 78, 82);
    cursor: pointer;
}

.linkBL:hover{
    filter: brightness(1.2);
}

.link:hover{
    filter: brightness(1.2);
}

.sectioheader {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    opacity: .8;
}

.curColor {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
    border: 3px solid white;
    border-radius: 100px;
}

.customerTimerContainer{
    display: flex;
    margin-top: 12px;

}


.customerTimerInput{
    background-color: transparent;
    color:white;
    border: none;
    width: fit-content;
    width: 45px;
    text-align: center;
    padding: 0px;
    padding-left: auto;
    padding-right: auto;
    margin-left: 10px;
    margin-right: 10px;
    height: 20px;
    border-bottom: 2px solid var(--brand-color);
    border-radius: 0px;

}

.initMessageInput {
    padding: 10px;
    background-color: rgba(24, 24, 26, 0.4);
    backdrop-filter: blur(30px);
    border-radius: 100px;
    font-weight: 700;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.859);
    font-family: 'DM Sans', sans-serif;
    border: 2px solid rgb(78, 78, 82);
    padding: 16px;
    padding-left: 24px;
    border: 1px solid #ccc;
}

.placementPillsSelected {
    background-color: var(--brand-color);
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 100px;
    font-weight: 700;
    outline: 0px solid var(--brand-color);
    transition: outline .2s linear;
}

.placementPillsSelected:hover{
    filter: brightness(1.2);
}
.placementPillsUnselected:hover{
    filter: brightness(1.2);
}

.placementPillsUnselected {
    background-color: transparent;
    outline: 2px solid var(--brand-color);
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    font-weight: 700;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 100px;
    transition: background-color .2s linear;
}

section {
    margin-bottom: 20px;
}

.appearanceSection,
.triggerSection,
.firstMessageSection {
    border-radius: 8px;
    padding: 0px;
}

.triggerSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}
.test_timer{
    background-color: var(--brand-color);
    border-radius: 100px;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 21px;
    font-weight: 700;
    cursor: pointer;
}
.test_timer:hover{
    filter: brightness(1.2);
}
.logoUpload {
    margin-top: 10px;
}

.uploadButtonContainer {
    position: relative;
  }
  
  .fileInput {
    display: none;
  }
  .closeBtnContainer{
    margin-left: -10px; 
    background-color: rgb(52, 52, 52);
    width: fit-content;
    height: 16px;
    border-radius: 100px;
    padding: 4px;
    opacity: .8;
  }
  .closeBtnContainer:hover{
    opacity: 1;
  }
  .uploadButton {
    background-color: var(--brand-color);
    color: #fff;
    padding: 10px 15px;
    font-weight: 700;
    font-size:16px;
    border: none;
    cursor: pointer;
    border-radius: 100px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 6px;
    margin-bottom: 10px;
  }
  
  .fileName {
    margin-top: 10px;
    font-size: 12px;
    color: #333;
  }



/* You can add more styles as needed */
:root {
    /* Colors */
    --brand-color: #5142f4;
    --secondary-color: #0f9d58;
    --background-color: black;
    --text-color: #333;
    --link-color: #1a0dab;
    --link-hover-color: #0066cc;
    --font-family: 'DM Sans', sans-serif;
    --font-size: 16px;
    --padding-small: 8px;
    --padding-medium: 16px;
    --padding-large: 24px;
    --border-radius: 8px;
    --border-width: 1px;
    --border-color: #ddd;
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --transition-duration: 0.3s;
}

::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: var(--brand-color);
    /* Set the color of the scrollbar thumb */
    border-radius: 4px;
    /* Optional: Add rounded corners to the scrollbar thumb */
}

body {
    background-color: black;
}

/* Collapsed Chat Control */
#or-wid-collapsed-chat-btn-br {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 60px;
    width: 60px;
    background-color: white;
    border-radius: 100px;
    z-index: 1000;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

#or-wid-collapsed-chat-btn-bl {
    position: fixed;
    bottom: 30px;
    left: 30px;
    height: 60px;
    width: 60px;
    z-index: 1000;
    background-color: white;
    border-radius: 100px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

#or-wid-collapsed-chat-btn-tr {
    position: fixed;
    top: 30px;
    right: 30px;
    height: 60px;
    width: 60px;
    background-color: white;
    border-radius: 100px;
    z-index: 1000;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}
#or-wid-collapsed-chat-btn-tl {
    position: fixed;
    top: 30px;
    left: 30px;
    height: 60px;
    width: 60px;
    background-color: white;
    border-radius: 100px;
    z-index: 1000;
    overflow: hidden;
    box-shadow: var(--box-shadow);
}

#or-wid-brand-logo-collaped-chat-btn {
    height:100%;
    width:100%;
    object-fit: cover;
}

#or-wid-inputContainer{
    display: flex;
    gap: 8px;
    padding:16px;
}

/* Chat Container */
.or-wid-chat-container {
    width: 350px;
    max-width: 80vh;
    min-height: 400px;
    padding: 16px;
    background-color: white;
    border-radius: var(--border-radius);
}

#or-wid-chat-name {
   font-size: 20px;
   font-family: var(--font-family);
   font-weight: 600;
   margin-top: auto;
   margin-bottom: auto;
   height: fit-content;
   text-align: left;
}

#or-wid-main-input-container {
    display: block;
    background-color: red;
    width: 100%;
    height: 50px;
}

#or-wid-main-input {
    width: inherit;
    margin: 0px;
    padding: 0px;
}



#or-wid-chatPane-br {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 400px;
    min-height: 480px;
    max-height: 800px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    background-color: white;
    z-index: 1000;
    flex-direction: column;
    transition: height .3s cubic-bezier(.33,0,.1,1);
    animation-name: or-wid-open;
    animation-timing-function: cubic-bezier(.33,0,.1,1);
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}

#or-wid-chatPane-bl {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 400px;
    min-height: 480px;
    max-height: 800px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    background-color: white;
    z-index: 1000;
    flex-direction: column;
    transition: height .3s cubic-bezier(.33,0,.1,1);
    animation-name: or-wid-open;
    animation-timing-function: cubic-bezier(.33,0,.1,1);
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}

#or-wid-chatPane-tl {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 400px;
    min-height: 480px;
    max-height: 800px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    background-color: white;
    z-index: 1000;
    flex-direction: column;
    transition: height .3s cubic-bezier(.33,0,.1,1);
    animation-name: or-wid-open;
    animation-timing-function: cubic-bezier(.33,0,.1,1);
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}

#or-wid-chatPane-tr {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 400px;
    min-height: 480px;
    max-height: 800px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    background-color: white;
    z-index: 1000;
    flex-direction: column;
    transition: height .3s cubic-bezier(.33,0,.1,1);
    animation-name: or-wid-open;
    animation-timing-function: cubic-bezier(.33,0,.1,1);
    animation-duration: 300ms;
    animation-fill-mode: forwards;
}

@keyframes or-wid-open {
    0%{
        min-height: 48px;
    }
    100%{
        min-height: 450px;
    }
}

.or-wid-FREcontainer {
    height: 100%;
    padding: 8px;
    text-align: center;
    font-family: var(--font-family);

}

#or-wid-logoContainer {
    display: block;
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    height: 46px;
    width: 46px;
    padding:0px;
    overflow: hidden;
    border-radius: 100px;
}

.or-wid-or-logo {
    height:100%;
    width:100%;
    object-fit: cover;
}

.or-wid-smallControl {
    border: 3px solid var(--brand-color);
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 50px;
    width: 50px;
    display: grid;
    place-content: center;
    border-radius: 100px;
}

.or-wid-header {
    position: relative;
    display: grid;
    place-content: center;
    grid-template-columns: 60px 1fr 20px;
    color: #fff;
    padding: 10px;
    backdrop-filter: blur(20px);
    opacity: 1;
    background-color: var(--brand-color);
    font-family: var(--font-family);
}

.or-wid-messages {
    flex: 1;
    padding: 16px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    font-family: var(--font-family);
}

.or-wid-msgContainer {
    position: relative;
}

.or-wid-audioIcon {
    position: absolute;
    background-color: var(--brand-color);
    display: grid;
    right: -10px;
    bottom: -22px;
    place-content: center;
    width: 30px;
    height: 30px;
    border: 4px solid white;
    border-radius: 100px;
}

.or-wid-sendBtn {
    display: grid;
    place-content: center;
    background-color: var(--brand-color);
    color: #fff;
    border: none;
    width: 40px;
    
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-family: var(--font-family);
}

.or-wid-sendBtn:hover{
    filter: brightness(1.12);
}

.or-wid-userMessage {
    position: relative;
    background-color: var(--brand-color);
    color: #fff;
    border-radius: 5px;
    padding: 12px 16px;
    width: fit-content;
    margin-left: auto;
    margin-bottom: 20px;
    font-family: var(--font-family);
    animation-name: or-wid-slideIn;
    animation-duration: .33s;
    animation-timing-function: cubic-bezier(0.33, 0, 0.165, 1);
}

@keyframes or-wid-slideIn {
    0% {
        opacity: 0;
        transform: translateY(100px) scale(.5);
    }

    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

.or-wid-botMessage {
    position: relative;
    background-color: #efecf1;
    width: fit-content;
    margin-right: auto;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 20px;
    font-family: var(--font-family);
    white-space: pre-line;
}

.inputContainer {
    display: grid;
    grid-template-columns: 1fr 40px;
    padding: 10px;
    align-items: center;
}

#or-wid-compose-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    height: 40px;
    padding-right: 30px;
    border-radius: 5px;
    font-weight: 500;
    outline: none;
    font-family: var(--font-family);
}

#or-wid-compose-input:focus {
    outline: 1px solid var(--brand-color);
    border: none
}

.or-wid-innerInput {
    position: relative;
    display: flex;
    width: 100%;
}

.or-wid-micContainer {
    display: grid;
    height: 100%;
    width: 16px;
    position: absolute;
    border-radius: 100px;
    top: 0px;
    right: 6px;
    opacity: .8;
    place-content: center;
}

.micContainer:hover {
    opacity: 1;
}

.unfilledMic {
    opacity: .75;

}

.or-wid-filledMic {
    animation-name: or-wid-pulse;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
}

.or-wid-unfilledMic:hover {
    color: var(--brand-color);
    opacity: 1;
}

.or-wid-listeningTxt {
    background-color: var(--brand-color);
    color: white;
    font-family: var(--font-family);
    text-align: center;
    font-size: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 8px 8px 0px 0px;

}

@keyframes or-wid-pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .7;
    }

    100% {
        opacity: 1;
    }
}
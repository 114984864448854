:root {
  /* Colors */
  --primary-color: #4285f4;
  /* Google Blue */
  --secondary-color: #0f9d58;
  /* Google Green */
  --background-color: black;
  /* Light Gray Background */
  --text-color: #333;
  --link-color: #1a0dab;
  /* Google Link Blue */
  --link-hover-color: #0066cc;

  /* Font */
  --font-family: 'DM Sans', sans-serif;
  /* Google's Font */
  --font-size: 16px;

  /* Spacing */
  --padding-small: 8px;
  --padding-medium: 16px;
  --padding-large: 24px;

  /* Border */
  --border-radius: 4px;
  --border-width: 1px;
  --border-color: #ddd;

  /* Box Shadow */
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Transition */
  --transition-duration: 0.3s;
}

.content-wrapper {
  margin: auto;
  padding-top: 60px;
  width: 80%;
}

.training-input-Container {
  position: relative;
  display: block;
  margin: auto;
  gap: 20px;
  width: 80%;
  margin-bottom: 30px;
  height: 50px;
  font-family: 'DM Sans';
  font-size: 20px;
  transition: all .3s cubic-bezier(.33, 0, .1, 1);
}

.traning-input {
  position: relative;
  width: 100%;
  padding: 10px;
  background-color: rgba(24, 24, 26, 0.4);
  backdrop-filter: blur(30px);
  border-radius: 100px;
  font-weight: 700;
  font-size: 20px;
  color:rgba(255, 255, 255, 0.859);
  font-family: 'DM Sans', sans-serif;
  border: 2px solid rgb(78, 78, 82);
  padding: 16px;
  padding-left: 24px;
  border: 1px solid #ccc;
}

.traning-input:focus{
  border: 1px solid HSLA(240,100%,70%,0.63);;
}
.traning-input:hover{
  border: 1px solid HSLA(240,100%,70%,0.63);;
}
.traning-btn-start{
  position: relative;
  display: block;
  height: 60px;
  border-radius: 100px;
  font-family: 'DM Sans';
  font-weight: 900;
  margin: auto;
  width: 250px;
  font-size: 16px;
  background: linear-gradient(50deg,#3748e4 25.61%,#37b0e4 89.23%);
}

/* Global Styles */
body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  /* background-color: var(--background-color); */
  color: var(--text-color);
  scroll-behavior: smooth;
  background-color: #e699ff;
  background-color: hsla(0, 0%, 0%, 1);
  background-image:
    radial-gradient(at 72% 53%, hsla(26, 0%, 7%, 1) 0px, transparent 50%),
    radial-gradient(at 100% 0%, hsla(240, 80%, 40%, 0.45) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(0, 0%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 66% 58%, hsla(337, 0%, 0%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(167, 100%, 69%, 0.4) 0px, transparent 50%),
    radial-gradient(at 98% 97%, hsla(240, 100%, 70%, 0.63) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(310, 0%, 9%, 0.27) 0px, transparent 50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  animation: moveGradients 2s linear infinite; 
}



.training-main-wrapper{
  transition: all .33s cubic-bezier(.33,0,.1,1);
  position: relative;
}



.logoH1{
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image:linear-gradient(100deg,#3748e4 15.61%,#37b0e4 69.23%);
}



/* Input Styles */
input,
textarea,
select {
  padding: var(--padding-small);
  margin-bottom: var(--padding-medium);
  width: 100%;
  box-sizing: border-box;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: var(--font-size);
}

/* Hover and Focus Styles */
input:hover,
textarea:hover,
select:hover,
input:focus,
textarea:focus,
select:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* Button Styles */
button {
  background-color: var(--primary-color);
  color: #fff;
  padding: var(--padding-medium);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-duration);
}

button:hover {
  filter:brightness(1.2)
}


/* Checkbox and Radio Button Styles */
input[type="checkbox"],
input[type="radio"] {
  margin-right: 5px;
}

/* Placeholder Styles */
::placeholder {
  color: #888;
}

/* Disable default browser styles for certain elements */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* Reset styles for some elements */
button,
input {
  overflow: visible;
}

/* Disable outline on Firefox */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* Additional styles for disabled elements */
[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

ul {
  padding: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.sr_wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 16px;
    overflow: hidden;
    text-overflow: ellipsis;

}
.app{
    display: flex;
    align-items: center;
    height: 100vh;
}

.headerContainer{
    position: absolute;
    display: flex;
    right: 36px;
    top: 36px;
 
    font-family: 'DM Sans';
    font-weight: 700;
    text-align: right;
    color:white;
}

.signUpBtn{
    padding: 8px 16px;
    opacity: .8;
    cursor: pointer;
    border-radius: 100px;
}
.loginBtn{
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 100px;
}

.contentWrapper {
    max-width: 900px;
    margin: auto;
    padding-bottom: 40px;
    padding-top: 0px;
    padding-right: 36px;
    padding-left: 36px;
    width: 80%;
    font-family: 'DM Sans';
}

.editBtn{
    position: absolute;
    display: grid;
    place-content: center;
    padding: 8px;
    border-radius: 10px;
    top: 16px;
    right: 16px;
    background-color: var(--brand-color);
    font-family: 700;
    opacity: .8;
}

.editBtn:hover{
    opacity: 1;
}

h3{
    color: rgba(255, 255, 255, .6)
}

.linkWrapper {
    display: grid;
    text-align: left;
    background-color: rgba(24, 24, 26, 0.4);
    backdrop-filter: blur(30px);
    border-radius: 12px;
    font-family: 'DM Sans', sans-serif;
    border: 1px solid rgb(78, 78, 82);
    overflow: hidden;
    margin-bottom: 20px;
    padding: 16px;
    padding-left: 24px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1;


}

.linkText {
    font-family: 'DM Sans';
    color: HSLA(240,100%,70%,0.63);
    white-space: pre-wrap;
    font-weight: 600;

}

::-webkit-scrollbar {
    width: 3px; /* Adjust the width as needed */

  }
  
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5); 
    border-radius: 10px;
  

  }
  
  /* Scrollbar Handle */
  ::-webkit-scrollbar-thumb {
    background-color: hsla(240, 100%, 70%, 0.63); /* Set the color of the scrollbar handle */
    border-radius: 6px; /* Optional: Add border-radius for rounded corners */
    width: 2px;
  }

@keyframes fadeInUp {
    0% {
        opacity: 0;
        height: 0px;
        transform: translateY(-30px) scale(.5);
    }

    100% {
        opacity: 1;
        height: auto;
        transform: translateY(0) scale(1);
        height: 100%;
    }
}




/* Add more nth-child rules as needed */


.genContainer{
    display: flex;
    position: relative;
    border-radius: 12px;
    font-family: 'DM Sans', sans-serif;
    color:white;
    padding: 20px;
    height: 90px;
    margin-top: 10px;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 0;
    // box-shadow: 0 0 10px teal; /* Initial box shadow color, adjust as needed *
   
}

.genStepWrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1400px;
    width:130%;
    transform: translate(-50%, -50%);
    background: conic-gradient(rgb(19, 17, 59), rgba(61, 61, 142, 1), rgba(44, 173, 111, 1), rgba(0, 48, 255, 1), rgb(19, 17, 59));
    animation: glow 2s linear 0s infinite forwards;
}

.genStepInner{
   
    position: absolute;
    background-color: rgba(24, 24, 26, 1);
    border-radius: 12px;
    font-family: 'DM Sans';
    height: 84px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99.4%;
   
}

.testMyAssistant{
    width: 100%;
    border-radius: 100px;
    font-family: 'DM Sans';
    background-color: hsl(240, 100%, 70%);
    font-weight: 700;
    font-size: 18px;
    margin-bottom:24px
}

.uploadDocs{
    display: block;
    width: fit-content;
    margin: auto;
    border-radius: 100px;
    font-family: 'DM Sans';
    border: 1px solid rgb(78, 78, 82);
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom:24px
}

.genTextContainer{
    padding: 16px;
}

.genh1{
    position: relative;
    color:white;
    font-size: 20px;;
    font-weight: 700;
}

.gensub{
    color: white;
    opacity: .8;
    margin-top: 6px;
    font-size: 16px;
}

@keyframes glow {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100%{
        transform: translate(-50%, -50%) rotate(360deg);
    }
  }

.overviewContaienr{
    backdrop-filter: blur(30px);
    border-radius: 12px;
    font-family: 'DM Sans', sans-serif;
    border: 1px solid rgb(78, 78, 82);
    color:white;
    padding: 20px;
    margin-top: 10px;
}

.footerContainer{
    position: absolute;
    bottom: 16px;
    color:white;
    font-family: 'DM Sans';
    display: flex;
    width:'fit-content';
    margin: auto;
    text-align: center;
    gap: 18px;
    margin-left: 18px;
}

.links{
    color:white;
    opacity: .5;
    font-family: 'DM Sans';
    font-weight: 700;
    font-size:12px;
}

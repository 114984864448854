.container{
    padding:20px;
    border-radius: 8px;
    margin-bottom:2px;
    margin-top:2px;
    border-radius: 12px;
    height: 420px;
    overflow: hidden;
    border: 1px solid rgb(78, 78, 82);
    background-color: rgba(24, 24, 26, 0.4);
    color:rgba(255,255,255,1);
    backdrop-filter: blur(30px);
}

.contentWrapper{
    height:200px;
    overflow: hidden;
    padding:0;
    margin-top: 16px;
    overflow-y: scroll;
    margin-bottom: 16px;
    opacity: .7;
}

.webpagePill{
    font-size: 12px;
    width: fit-content;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 800;
    background-color: HSLA(240,100%,70%,0.63);
    border-radius: 100px;
    color:rgba(255,255,255,1);
}

.title{
  color: white;
  opacity: .85;
  height: 70px
}
.score{
    opacity: .8;
   
    margin:0;
}
.link{
    color:HSLA(240,100%,70%,0.63);
    width:90%;
    font-weight: 700;
    word-wrap: break-word;

}
.bouncing-typing-indicator {
    display: flex;
    align-items: center;
    background-color: #efecf1;
    padding: 14px;
    width: fit-content;
    border-radius: 8px;
    opacity: 0;
    animation: scaleIn .4s cubic-bezier(.33,0,.1,1) .5s forwards;
  }

  @keyframes scaleIn {
    0%{ 
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;;
        transform: scale(1);
    }
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: #a2a2a2;
    border-radius: 50%;
    margin: 0 4px;
    animation: bounce 1.6s infinite;
    animation-timing-function: cubic-bezier(.33,0,.67,1);
  }
  
  #dot1 {
    animation-delay: .2s;
  }
  
  #dot2 {
    animation-delay: 0.35s;
  }
  
  #dot3 {
    animation-delay: .45s;
  }
   
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-7px);
      background-color: #b9b9b9; /* Change to red during the bounce */
    }
    60% {
      transform: translateY(-5px);
      background-color: #515151; /* Change back to the original color */
    }

  }